<template>

	<div id="board-view" class="container">
		<div class="content">
			<div class="content_head">
				<div class="inner">
					<div class="breadcrumb">
						<ul>
							<li><a href="#" class="ico_home" title="Home"></a></li>
							<li>고객센터</li>
							<li>문의하기</li>
						</ul>
					</div>
					<h2 class="page_title">문의하기</h2>
					<!--<nav class="page_tab">
						<ul class="tab_list swiper-wrapper">
							<li class="tab_item swiper-slide"><a href="#">공지사항</a></li>
							<li class="tab_item swiper-slide"><a href="#">FAQ</a></li>
							<li class="tab_item swiper-slide active"><a href="#">문의하기</a></li>
						</ul>
					</nav>-->
				</div>
			</div>
			<div class="content_body">
				<div class="inner">
					<div class="tbl_input">
						<table>
							<tr>
								<th>이메일 주소 <span class="require">*</span></th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" placeholder="이메일 주소를 입력해 주세요." v-model="boardVO.writerKey">
									</div>
								</td>
							</tr>
							<tr>
								<th>제목 <span class="require">*</span></th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" placeholder="제목을 입력해 주세요." v-model="boardVO.title">
									</div>
								</td>
							</tr>
							<tr>
								<th>문의 내용 <span class="require">*</span></th>
								<td>
									<div class="inp_box">
										<textarea  v-model="boardVO.contentHtml" name="" class="tta" cols="30" rows="5" placeholder="전화 문의를 희망하시는 경우 연락처를 남겨주세요." ></textarea>
									</div>
								</td>
							</tr>
							<tr>
								<th>파일 첨부</th>
								<td>
									<div class="inp_box i1b2 file_box">
										<input type="text" class="inp file_name" disabled v-model="filename"
											   placeholder="파일은 최대 용량 10MB 이하로 등록해 주세요.">
										<input type="file" class="inp_file" @change="handleFileChange">
										<button type="button" class="btn btn_upload" @click="clickFileInput">파일 찾기
										</button>
										<button type="button" class="btn" @click="deleteFile">삭제</button>
									</div>
								</td>
							</tr>
						</table>
					</div>
					<div class="button_area align_center">
						<button type="button" class="btn_cancle btn_primary" @click="uploadFile">등록하기</button>
<!--						<button type="button" class="btn_submit btn_black">신청하기</button>-->
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import $ from "jquery";
export default {
	name: "boardView",
	data () {

		console.log(this.getSessionProc())
		return {
			boardVO: new this.BoardVO(this.getSessionProc()),
			logoFile: {},
			filename: "",
			fileIdxs: ''
		}
	},
	mounted() {
		this.initForm()
	},
	methods:{

		async initForm(){
			this.boardInfoVO = await this.getBoardInfo('cms_qna')

		},
		boardBack(){
			history.back()
		},
		async setBoard() {

			//해당 화면은 작성 화면
			//view는 별도로 존재

			// e.preventDefault()

			const self = this;


			this.boardVO.boardKey = 'cms_qna'

			// this.boardVO.cateCode = this.boardInfoVO.cateCode

			//
			// this.boardVO.fileIdxs = this.fileIdxs.join(",")
			// this.boardVO.thumbIdx = this.thumbIdx
			// console.log( this.boardVO )
			//
			// if ( this.stringUtils.isEmpty( this.boardVO.boardKey ) ) {
			//     this.swalUtils.gritter("error", "정상적인 접근이 아닙니다. 다시 시도해주세요.", "error", 800)
			//         .then(() => {
			//             history.back();
			//         })
			//     return
			// }

			this.boardVO.fileIdxs = this.fileIdxs

			this.$eventBus.$emit("startProgress")
			console.log(this.boardVO)
			this.axiosCaller.post( this, this.APIs.BOARDS + "/setBoardOne", this.boardVO, (res) => {
				this.$eventBus.$emit("doneProgress")
				const result = res.data
				console.log( result )
				if ( result.status === "ok" ) {

					self.swalUtils.gritter("문의하신 내용은 잘 접수되었습니다. 3일 이내에 등록하신 이메일 주소로 안내드리겠습니다. 감사합니다.", "", "success", 4500)
						.then( () => {
							location.href='/'
						});

					// this.swalUtils.gritter("Success", "저장되었습니다", "success", 800)
					// 		.then((res) => { // eslint-disable-line no-unused-vars
					// 			// history.back()
					//
					// 			location.reload();
					// 		})
				}
			})

		},
		setFileName() {
			const self = this;

			if (this.serviceApplyVO.fidx != null && this.serviceApplyVO.fidx > 0) {
				//file 가져오기
				this.axiosCaller.post(this, this.APIs.BOARD_FILES + "/findFile", {fidx: this.serviceApplyVO.fidx}, (res) => {
					this.$eventBus.$emit("doneProgress")
					const result = res.data
					console.log(result)
					if (result.status === "ok") {
						if (result) {
							self.filename = result.fileVO.filename;
						}
					} else {
						self.swalUtils.gritter(result.msg, "", "error", 3000)
					}
				});
			}
		},
		clickFileInput() {
			console.log('::::::::::::::clickFileInput')
			this.deleteFile();
			//$(".inp_file").click();
		},
		//파일 업로드
		uploadFile() {
			let self = this;
			let formData = new FormData;
			console.log('::::::this.logoFile', this.logoFile)
			formData.append('files', this.logoFile)
			var email =  this.getSessionProc()?.emailId || this.boardVO?.writerKey
			if (email != null){
				formData.append('LOGIN_ID', this.getSessionProc()?.emailId || this.boardVO?.writerKey)
			}


			this.axiosCaller.postFormData(this, this.APIs.BOARD_FILES + "/upload", formData, (res) => {
				this.$eventBus.$emit("doneProgress")
				const result = res.data
				//console.log(result)
				console.log(':::::::::::postFormData')
				if (result.status === "ok") {

					console.log(result)
					result.fileList.forEach((item)=>{

						self.fileIdxs = item.fidx
					})

					self.setBoard();

					// self.serviceApplyVO.fidx = result.fileList[0].fidx;
					// console.log(':::::::::::postFormData fidx', self.serviceApplyVO.fidx)
					// self.goNextPage();
				} else {
					console.log('file upload 이슈')
				}
			})
		},

		//파일 교체
		handleFileChange(e) {
			console.log('::::::::::handleFileChange')
			this.logoFile = e.target.files[0];
			this.filename = e.target.files[0].name;
			this.changeLogo = true;
		},
		deleteFile() {
			let agent = navigator.userAgent.toLowerCase();
			if ((navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (agent.indexOf("msie") != -1)) {
				// ie 일때 input[type=file] init.
				$(".inp_file").replaceWith($(".inp_file").clone(true));
			} else {
				//other browser 일때 input[type=file] init.
				$(".inp_file").val("");
			}
			this.fileName = "";
		},
	}
}
</script>

<style scoped>
	.tab_item {
		margin-right: 24px;
	}
</style>