<template>

	<board-view board-key="qna"></board-view>

</template>

<script>
import BoardView from "@/components/boards/boardView";
export default {
	name: "qna",
	components: {BoardView}
}
</script>

<style scoped>

</style>